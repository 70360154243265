<template>
  <div class="cost">
    <Header title="工作台" index="首页" beforeTitle="工作台" />
    <div class="worknot">
      <!-- 公告通知 -->
      <div class="notice" id="notic">
        <div class="not">
          <h1>
            公告通知
            <img src="../assets/images/notice_opcity.png" alt width="18px" />
          </h1>
          <span @click="noticeMore()">
            更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <ul>
          <li
            v-for="(item, index) in noticeList.data"
            :key="index"
            @click="noticeDetail(item)"
            class="noticeIng"
          >
            <div class="litext" :class="item.status == 1 ? 'isActive' : 'noActive'">{{ item.title }}</div>
            <span :class="item.status == 1 ? 'isActive' : 'noActive'">
              {{
              item.update_time
              }}
            </span>
            <el-badge v-if="!item.status" :is-dot="true" class="index" />
          </li>
        </ul>
      </div>
      <!-- 备忘录 -->
      <div class="notice aides">
        <div class="not">
          <h1>
            备忘录
            <img
              style="cursor: pointer"
              @click="memoAdd()"
              src="../assets/images/roundadd.png"
              alt
              width="18px"
            />
          </h1>
          <span @click="memoMore()">
            更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <ul v-if="memolist.data != null && memolist.data.length > 0">
          <li
            @click="memoDetail(item)"
            v-for="(item, index) in memolist.data.slice(0, 5)"
            :key="index"
          >
            <img src="../assets/images/roundblackjiantou.png" alt width="18px" />
            <span>{{ item.create_time }}</span>
            <img src="../assets/images/bluesmalljiantou.png" alt width="10px" />
            {{ item.content }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 工作台模块( 按钮 ) -->
    <div class="workbench">
      <ul>
        <!-- 平台管理 -->
        <li v-if="privilige_list.workbench.includes('platform')">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="platform()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in platformList"
                      :key="index"
                      :label="i.id"
                      @change="onPlatform(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>平台管理</dt>
            <div class="workDd" v-loading="loadPlatform">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.platform" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
                <el-badge v-if="item.id == 65" :value="mission_count" class="item" />
              </dd>
            </div>
          </dl>
        </li>
        <!-- 智能匹配 -->
        <!-- v-if="privilige_list.workbench.includes('platform')" -->
        <li v-if="false">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="match()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in matchList"
                      :key="index"
                      :label="i.id"
                      @change="onMatch(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>智能匹配</dt>
            <div class="workDd" v-loading="loadMatch">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.match" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
        <!-- 客户管理 -->
        <!-- v-if="privilige_list.workbench.includes('custom')" -->
        <li v-if="false">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="custom()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in customList"
                      :key="index"
                      :label="i.id"
                      @change="onCustom(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>客户管理</dt>
            <div class="workDd" v-loading="loadCustom">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.custom" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
        <!-- 合同/项目管理 -->
        <li v-if="privilige_list.workbench.includes('contract')">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="contract()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in contractList"
                      :key="index"
                      :label="i.id"
                      @change="onContract(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>合同/项目管理</dt>
            <div class="workDd" v-loading="loadContract">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.contract" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
        <!-- 科创模块 -->
        <li v-if="privilige_list.workbench.includes('scientific')">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="scientifi()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in scientifiList"
                      :key="index"
                      :label="i.id"
                      @change="onScientifi(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>科创模块</dt>
            <div class="workDd" v-loading="loadScientifi">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.scientifi" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
        <!-- 财务管理 -->
        <li v-if="privilige_list.workbench.includes('finance')">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="finance()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in financeList"
                      :key="index"
                      :label="i.id"
                      @change="onFinance(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>财务管理</dt>
            <div class="workDd" v-loading="loadFinance">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.finance" :key="index">
                <el-badge v-if="item.id == 76" :value="receipt_count" class="item" />
                <el-badge v-if="item.id == 77" :value="finance_count" class="item" />
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
        <!-- 政策/数据管理 -->
        <li v-if="privilige_list.workbench.includes('policy')">
          <el-row class="block-col-2">
            <el-col :span="12">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i @click="data()" class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 150px">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(i, index) in dataList"
                      :key="index"
                      :label="i.id"
                      @change="onData(i.id)"
                      :checked="Boolean(i.status)"
                    >{{ i.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <dl>
            <dt>政策/数据管理</dt>
            <div class="workDd" v-loading="loadData">
              <dd @click="workUrl(item)" v-for="(item, index) in workList.data" :key="index">
                <img :src="require(`../assets/images/${item.icon}.png`)" />
                <p>{{ item.name }}</p>
              </dd>
            </div>
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      loadPlatform: false,
      loadMatch: false,
      loadCustom: false,
      loadContract: false,
      loadScientifi: false,
      loadFinance: false,
      loadData: false,
      contract_count: "",
      finance_count: "",
      mission_count: "",
      receipt_count: "",
      checkList: [],
      noticeParams: {
        type: "workbench",
        limit: 5,
      },
      platformList: [],
      contractList: [],
      scientifiList: [],
      financeList: [],
      dataList: [],
      matchList: [],
      customList: [],
      privilige_list: {},
    };
  },
  computed: {
    ...mapState(["memolist", "noticeList", "workList"]),
  },
  mounted () {
    this.axios.get("/api/mission/mission_count").then((res) => {
      this.contract_count = res.data.contract_count;
      this.finance_count = res.data.finance_count;
      this.mission_count = res.data.mission_count;
      this.receipt_count = res.data.receipt_count;
    });
  },
  created () {
    this.$store.dispatch("getmemolist", this.listParams);
    this.$store.dispatch("getNoticeList", this.noticeParams);
    console.log(this.noticeList, "工作台公告通知列表");
    this.$store.dispatch("getWorkList", this.workParams);
    console.log(this.workList, "工作台模块列表");
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
  methods: {
    // 客户管理下拉菜单
    custom () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "custom" },
        })
        .then((res) => {
          this.customList = res.data;
        });
    },
    // 智能匹配下拉菜单
    match () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "match" },
        })
        .then((res) => {
          this.matchList = res.data;
        });
    },
    // 政策/数据管理下拉菜单
    data () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "data" },
        })
        .then((res) => {
          this.dataList = res.data;
        });
    },
    // 财务管理下拉菜单
    finance () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "finance" },
        })
        .then((res) => {
          this.financeList = res.data;
        });
    },
    // 科创模块下拉菜单
    scientifi () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "scientifi" },
        })
        .then((res) => {
          this.scientifiList = res.data;
        });
    },
    // 合作/项目管理下拉菜单
    contract () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "contract" },
        })
        .then((res) => {
          console.log(res, "contract");
          this.contractList = res.data;
        });
    },
    // 平台管理下拉菜单
    platform () {
      this.axios
        .get("/api/member/get_module_workbench", {
          params: { module: "platform" },
        })
        .then((res) => {
          console.log(res, "res");
          this.platformList = res.data;
        });
    },
    // 平台管理下拉菜单checkbox勾选/取消勾选 事件
    onPlatform (id) {
      console.log("选中/未选中", id, this.checkList.join(), this.workList);
      this.loadPlatform = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "platform",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadPlatform = false;
        });
      // this.loading = true;
    },
    // 智能匹配下拉菜单勾选/取消勾选 事件
    onMatch () {
      this.loadMatch = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "match",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadMatch = false;
        });
      // this.loading = true;
    },
    // 客户管理下拉菜单勾选/取消勾选 事件
    onCustom () {
      this.loadCustom = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "custom",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadCustom = false;
        });
      // this.loading = true;
    },
    // 合作/项目管理下拉菜单checkbox勾选/取消勾选  事件
    onContract (id) {
      console.log("选中/未选中", id, this.checkList.join(), this.workList);
      this.loading = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "contract",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loading = false;
        });
      // this.loading = true;
    },
    // 科创模块下来菜单勾选/取消勾选 事件
    onScientifi () {
      this.loadScientifi = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "scientifi",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadScientifi = false;
        });
      // this.loading = true;
    },
    // 业务管理下拉菜单勾选/取消勾选 事件
    onFinance () {
      this.loadFinance = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "finance",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadFinance = false;
        });
      // this.loading = true;
    },
    // 政策/数据管理下拉菜单勾选/取消勾选 事件
    onData () {
      this.loadData = true;
      this.axios
        .post("/api/member/save_module_workbench", {
          module: "data",
          workbench_id: this.checkList.join(),
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$store.dispatch("getWorkList", this.workParams);
          this.loadData = false;
        });
      // this.loading = true;
    },
    workUrl (item) {
      this.$router.push({ path: item.url });
      console.log(item, "itemITEM");
    },
    // 工作台企业结果通道
    qyResult () {
      this.$router.push("/work_bench/bus_end");
    },
    // 工作台产品结果通道
    cpResult () {
      this.$router.push("/work_bench/peoduct_end");
    },
    // 工作台匹配产品通道
    matchingCp () {
      this.$router.push("/match/matchCo");
    },
    // 工作台匹配企业通道
    matchingQy () {
      this.$router.push("/match/matchBus");
    },
    // 新增备忘录
    memoAdd () {
      this.$router.push("/memo/memoAdd");
    },
    // 备忘录更多
    memoMore () {
      this.$router.push("/memo/memoMore");
    },
    //备忘录详情
    memoDetail (item) {
      this.$router.push({ path: "/memo/memoDetail", query: { id: item.id } });
      console.log("备忘录详情id", item.id);
    },
    // 发布通知
    noticeAdd () {
      this.$router.push("/notice/add");
    },
    //公告通知详情
    noticeDetail (item) {
      this.$router.push({
        path: "/notice/detail_notice",
        query: { id: item.id },
      });
      console.log("工作台跳转公告详情参数id", item.id);
    },
    // 公告通知更多
    noticeMore () {
      this.$router.push("/notice/noticeMore");
    },
  },
};
</script>

<style scoped="scoped">
.workDd {
  display: flex;
  padding-top: 10px;
  overflow: auto;
}
.workbench ul li {
  position: relative;
  width: 45%;
  height: 100%;
  margin-right: 2%;
  margin-top: 20px;
  background: #fff;
  padding: 2%;
  float: left;
  /* max-height: 192px; */
  overflow: hidden;
}
.workbench ul li:nth-child(2n) {
  margin-right: 0;
}
.workbench dl dt {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #000000;
}
.workbench dl dd {
  /* float: left; */
  margin-left: 0;
  margin-right: 60px;
  cursor: pointer;
  position: relative;
  overflow: visible;
}
.workbench dl dd p {
  text-align: center;
  color: #909399;
}
.workbench .img {
  width: 50%;
  height: 50%;
  display: block;
  margin: 0 auto;
}
#notic ul li {
  color: #909399;
}
/* #notic ul li:hover {
  color: #909399;
} */
.notice {
  width: 45%;
  background: #fff;
  margin-top: 20px;
  padding: 2%;
  max-height: 192px;
  overflow: hidden;
}
.notice .not {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notice .not h1 {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
  line-height: 14px;
  display: flex;
  align-items: center;
}
.notice .not h1 img {
  margin-left: 10px;
}
.notice .not i {
  color: #a6abc7;
}
.notice .not span {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: right;
  color: #909399;
  line-height: 28px;
  position: relative;
  cursor: pointer;
}
/* .notice .not span:hover {
  text-decoration: underline;
} */

.notice ul {
  margin-top: 14px;
  width: 104%;
  height: 80%;
  overflow: hidden;
  transform: translateX(-2%);
}
.notice .litext {
  width: 80%;
  overflow: hidden;
  /*文本不会换行*/
  white-space: nowrap;
  /*当文本溢出包含元素时，以省略号表示超出的文本*/
  text-overflow: ellipsis;
}
.notice ul li {
  box-sizing: border-box;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  /*文本不会换行*/
  white-space: nowrap;
  /*当文本溢出包含元素时，以省略号表示超出的文本*/
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #909399;
  line-height: 28px;
}
.notice ul li:hover {
  background: #f2f3f9;
  cursor: pointer;
}
.notice ul li i {
  width: 6px;
  height: 6px;
  background: #ff2020;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 8px;
}
.worknot {
  display: flex;
  justify-content: space-between;
}
.aides ul {
  width: 104%;
  height: 80%;
  overflow: hidden;
  padding: 0 18px;
  transform: translateX(-4%);
}
.aides ul li {
  display: block;
}
.aides ul li img {
  vertical-align: middle;
}
.aides ul li i {
  display: none;
}
.aides ul li span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  line-height: 28px;
  margin: 0 26px;
}
.aides ul li img:nth-child(3) {
  margin-right: 25px;
}
.el-checkbox {
  display: block;
  margin: 10px 0 10px 20px;
}
.el-icon-more {
  display: block;
  font-size: 20px;
  width: 20px;
  color: #d3d8e2;
}
.el-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
.el-dropdown:hover {
  cursor: pointer;
}
.index {
  position: absolute;
  right: 8px;
}
.isActive {
  color: #d3d8e2;
}
.noActive {
  color: #909399;
}
.item {
  position: absolute;
  top: -8px;
  right: -8px;
}
</style>
